<template>
  <div class="bg">
    <div class="wrapper">
      <div>
        <div _ngcontent-c11="" class="page1">
          <p _ngcontent-c11="" class="em2">在中国共产党第十九届中央委员会第六次全体会议期间，北京广播电视台推出了系列报道，展示北京在建设全国科技创新方面的成绩。天空卫士作为北京经济技术开发区优秀科技企业代表，也是此次被采访公司中唯一一家从事数据安全的科技公司接受了《北京新闻》栏目的采访。《北京新闻》是北京电视台收视率最高的新闻节目，一直担当北京市改革开放、经济发展、社会进步的重要窗口。</p>
          <div _ngcontent-c11="" class="img"><img _ngcontent-c11="" alt="" src="../../assets/images/djt/page1-0.jpg"></div>
          <p _ngcontent-c11="" class="em2">在本次采访中，天空卫士向记者展示了最新研发的产品——数据安全治理自动化系统（DSAG）部分功能。这一系统通过对网页、电子邮件、USB设备、即时通讯工具等协议的内容进行识别和检查，对网络通道、终端、云端的数据实现安全防护。天空卫士研发人员已经研发出特有的机器学习技能，让机器对数据进行实时智能防护。</p>
          <div _ngcontent-c11="" class="img"><img _ngcontent-c11="" alt="" src="../../assets/images/djt/page1-1.jpg"></div>
          <p _ngcontent-c11="" class="em2">天空卫士董事、合伙人、研发副总裁柳赛普在采访中解释道：所有在数据流转中的数据是符合我们数据安全管理人员定义的策略，只有在满足策略放行的条件下才能放行，但是如果命中了我们的安全策略，认为这个数据不安全，我们可以以加密的方式或者脱敏的方式让你发送出去，或者干脆拒绝掉。</p>
          <p _ngcontent-c11="" class="em2">我国数字经济发展已经形成规模和速度优势，正面临全面优化升级和深度融合发展。天空卫士作为国内数据安全技术的领导者，多年来一直深耕数据安全领域，多项技术获得重大突破，协助企业不断提高数据安全治理水平，致力于为数字化经济建设健康发展保驾护航。</p>
        </div>
      </div>
    </div>
  </div>
  
</template>

<style lang="less" scoped>
  .left {
    margin: 20px 0;
    font-size: 22px;
  }
  .em2{
  text-indent: 2em;
  line-height: 40px;
}
.center{
  text-align: center;
}
.img {
  width: 100%;
  display: flex;
  padding: 10px 0;
  align-items: center;
  justify-content: center;
}
.img img {
  width: 600px;
}
  .bg {
    color: #333;
    font-size: 16px;
    background: #F7F6FA;
    padding: 60px 0;
    .activity {
      background: #fff;
      padding: 20px;
    }
  }
  .text-title {
    font-size: 24px;
    font-weight: bold;
    color:#333;
    text-align: center;
    margin-bottom: 18px;

  }
  .title {
    margin-right: 7px;
    font-size: 18px;
    color: #217aff;
    h6 {
      font-size: 22px;
  margin-right: 7px;

    }
  }
  .intro {
    text-indent: 2em;
  line-height: 40px;
  font-size: 16px;
  color: #333;

  }
  
  
  
  </style>
